.App {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.logo-text {
  font-weight: 700;
  font-size: 22px;
  width: 85%;
  line-height: 32px;
  text-align: center;
  color: #fff;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(135, 177, 255, 0.7) !important;
}

.ant-layout-sider {
  background-color: #fff;
}

.ant-menu.ant-menu-dark {
  background-color: #fff;
  color: #8a8a8a;
  /* background-color: rgba(135, 177, 255, 0.7) !important; */
}

#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.ant-table-thead > tr > th {
  border-bottom: none !important;
}

ul.ant-pagination.ant-table-pagination {
  margin: 1rem;
}

.big-number-table {
  background: #ffffff;
}

.gray-border {
  border: 1px solid #dfdfdf;
  border-radius: 6px;
  background-color: #fff;
}

/* big number summary table  */
.border-bar:not(:last-child) {
  border-right: 1.7px solid #ddd;
}
.summary-table-value {
  font-weight: 500;
  font-size: 24px;
  text-align: right;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f5f5f5;
}

.header-container {
  background: #00008ba9;
  box-sizing: border-box;
}

.outlet-list-container {
  display: grid;
  gap: 10px;
  padding: 5px 12px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.select-btn {
  height: 55px;
  white-space: initial;
}

.dot-circle-css {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

.ant-checkbox-group-item {
  margin-top: 0.5rem;
}

.red-bg {
  background-color: hsl(353, 87%, 92%) !important;
}
.green-bg {
  background-color: hsl(98, 29%, 85%) !important;
}

@media only screen and (max-width: 600px) {
  .ant-select-selector {
    margin-top: 0.5rem;
  }
} ;
